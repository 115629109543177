import { responseErrors } from 'constant';
import { i18n } from 'utils';

const getResponseError = (response, verbose = false) => {
  if (response?.ok) {
    return null;
  }
  if (!response) {
    return i18n.t('errors.RESPONSE_ERROR');
  }
  if ((response.status != 500 || verbose) && response.data?.message) {
    return response.data.message;
  }
  const { problem } = response;
  if (problem) {
    if (problem === responseErrors.CLIENT_ERROR) {
      return i18n.t('errors.CLIENT_ERROR');
    }
    if (problem === responseErrors.SERVER_ERROR) {
      return i18n.t('errors.SERVER_ERROR');
    }
    if (problem === responseErrors.TIMEOUT_ERROR) {
      return i18n.t('errors.TIMEOUT_ERROR');
    }
    if (problem === responseErrors.NETWORK_ERROR) {
      return i18n.t('errors.NETWORK_ERROR');
    }
    if (problem === responseErrors.CANCEL_ERROR) {
      return i18n.t('errors.CANCEL_ERROR');
    }
    if (problem === responseErrors.CONNECTION_ERROR) {
      return i18n.t('errors.CONNECTION_ERROR');
    }
  }
  return i18n.t('errors.GENERAL_ERROR');
};

export default getResponseError;

import { clone } from 'lodash';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { select, takeLatest, call, put } from 'redux-saga/effects';

import {
  requestAmount,
  requestOpportunity,
  requestApply,
  requestToggles,
  requestSetOpportunityTag,
  requestSearch,
  opportunityViewsRequest,
} from 'services/opportunity';
import appCreator from 'store/app/actions';
import companyCreator from 'store/company/actions';
import {
  getOpportunities as getCompanyOpportunities,
  getOpportunitiesMeta as getCompanyOpportunitiesMeta,
} from 'store/company/selectors';
import opportunityCreator, { opportunityTypes } from 'store/opportunity/actions';
import { getOpportunities, getOpportunitiesMeta } from 'store/opportunity/selectors';
import { getResponseError, getVerbPastToggle, i18n } from 'utils';

export function* search({ filters }) {
  try {
    const response = yield call(requestSearch, filters);
    const error = yield getResponseError(response);
    const { data = [], meta = {} } = response.data;
    if (!error) {
      yield put(opportunityCreator.opportunitySearchSuccess(data, meta));
    } else {
      yield put(opportunityCreator.opportunitySearchError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunitySearchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* fetchAmount() {
  try {
    const response = yield call(requestAmount);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(opportunityCreator.opportunityFetchAmountSuccess(response.data.totalAmount));
    } else {
      yield put(opportunityCreator.opportunityFetchAmountError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunityFetchAmountError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* find({ opportunityId }) {
  try {
    const response = yield call(requestOpportunity, opportunityId);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(opportunityCreator.opportunityFindSuccess(response.data.opportunity));
    } else {
      yield put(opportunityCreator.opportunityFindError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunityFindError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* apply({ opportunity }) {
  try {
    const response = yield call(requestApply, opportunity.id, {
      whyOurCompanyReason: opportunity.whyOurCompanyReason,
      transcript: opportunity.transcriptUrl,
      portfolioUrl: opportunity.portfolioUrl,
      isAgree: opportunity.isAgree,
      opportunityLocationsIds: opportunity.opportunityLocationsIds,
    });
    const error = yield getResponseError(response, true);

    if (!error) {
      yield put(opportunityCreator.opportunityApplySuccess(response.data));
    } else {
      yield put(opportunityCreator.opportunityApplyError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunityApplyError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* toggles({ opportunity, entity }) {
  try {
    const entityInPast = getVerbPastToggle(entity);
    const response = yield call(requestToggles, opportunity.id, !opportunity[entityInPast], entity);
    const error = yield getResponseError(response);

    if (entity === 'save') {
      const companyOpportunities = clone(yield select(getCompanyOpportunities));
      const meta = clone(yield select(getCompanyOpportunitiesMeta));
      if (!isEmpty(companyOpportunities)) {
        const index = findIndex(companyOpportunities, opp => opp.id === opportunity.id);
        companyOpportunities[index] = {
          ...opportunity,
          [entityInPast]: !opportunity[entityInPast],
        };
        yield put(companyCreator.companyOpportunitiesSuccess(companyOpportunities, meta));
      }
    }

    if (!error) {
      const opportunities = clone(yield select(getOpportunities));
      const meta = clone(yield select(getOpportunitiesMeta));
      if (!isEmpty(opportunities)) {
        const index = findIndex(opportunities, opp => opp.id === opportunity.id);
        opportunities[index] = { ...opportunity, [entityInPast]: !opportunity[entityInPast] };
      }
      yield put(
        opportunityCreator.opportunityTogglesSuccess(
          { opportunities, meta },
          { ...opportunity, [entityInPast]: !opportunity[entityInPast] }
        )
      );
    } else {
      yield put(opportunityCreator.opportunityTogglesError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunityTogglesError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* setOpportunityTag({ opportunityId, tag, entity }) {
  try {
    const response = yield call(requestSetOpportunityTag, opportunityId, tag);
    const error = yield getResponseError(response);

    if (!error) {
      if (entity === 'dashboard') {
        const opportunities = yield select(getOpportunities);
        const opportunitiesMetadata = yield select(getOpportunitiesMeta);
        const opportunity = opportunities.find(({ id }) => id === opportunityId);
        opportunity.studentTag = response.data.opportunity.studentTag;
        opportunity.applied = response.data.opportunity.applied;
        yield put(appCreator.appSearchSuccess(null, { data: opportunities, meta: opportunitiesMetadata }));
      }
      yield put(opportunityCreator.opportunitySetTagSuccess(response.data.opportunity));
    } else {
      yield put(opportunityCreator.opportunitySetTagError(error));
    }
  } catch (error) {
    yield put(opportunityCreator.opportunitySetTagError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* opportunityViewsRequestSaga({ opportunityId }) {
  try {
    yield call(opportunityViewsRequest, opportunityId);
  } catch (error) {
    console.error(error);
  }
}

export function* opportunitySaga() {
  yield takeLatest(opportunityTypes.OPPORTUNITY_SEARCH_REQUEST, search);
  yield takeLatest(opportunityTypes.OPPORTUNITY_FIND_REQUEST, find);
  yield takeLatest(opportunityTypes.OPPORTUNITY_FETCH_AMOUNT_REQUEST, fetchAmount);
  yield takeLatest(opportunityTypes.OPPORTUNITY_APPLY_REQUEST, apply);
  yield takeLatest(opportunityTypes.OPPORTUNITY_TOGGLES_REQUEST, toggles);
  yield takeLatest(opportunityTypes.OPPORTUNITY_SET_TAG_REQUEST, setOpportunityTag);
  yield takeLatest(opportunityTypes.OPPORTUNITY_VIEWS_REQUEST, opportunityViewsRequestSaga);
}
